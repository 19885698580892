import { extend, merge, trimEnd, noop, get, isObject } from 'lodash';
import { retryRequest, urijs } from '@wix/communities-blog-client-common';

export default function createRequest({
  baseUrl = '',
  getInstance = () => null,
  locale = 'en',
  groupId,
  cookie,
  performanceTracker = { trackStart: noop, trackEnd: noop },
  siteRevision,
  trackError = () => {},
  logResponse = () => {},
  petriOvr = '',
} = {}) {
  const defaultHeaders = {};
  for (const [key, value] of [
    ['cookie', cookie],
    ['group-ip', groupId],
    ['x-wix-site-revision', siteRevision],
  ]) {
    if (value) {
      defaultHeaders[key] = value;
    }
  }

  const request = (path, config = {}) => {
    const parseResponse = config.parseHeaders ? parseHeadersAndBody : parseBody;
    const retryCount = config.retry;
    const retryTimeout = 9000;
    delete config.retry;
    delete config.parseHeaders;

    const instance = getInstance();

    extend(config, {
      headers: {
        instance,
        Authorization: instance,
        locale,
        ...config.headers,
        ...defaultHeaders,
      },
      credentials: 'same-origin',
    });

    const marker = performanceTracker.trackStart(
      `${new Date().toISOString().slice(11)} ${config.method || 'GET'} ${path}`,
    );
    const url = appendPetriOvr(`${trimEnd(config.baseUrl || baseUrl, '/')}${path}`, petriOvr);
    const start = () => abortableFetch(`${url}`, config);
    const parse = response =>
      Promise.resolve(response)
        .then(handleSuccess)
        .then(parseResponse)
        .then(r => {
          performanceTracker.trackEnd(marker);
          return r;
        })
        .then(trackResponse(path, logResponse, trackError))
        .catch(error => {
          const f = JSON.stringify;
          const status = get(error, 'status');
          if (isObject(error) && !error.stack) {
            const message = `request failed: url=${url} status=${status} config=${f(config)} error=${f(error)}`;
            error.stack = new Error(message).stack;
          }
          trackError(`request error: path=${path}, status=${status}, error=${f(error)}`);
          return Promise.reject(error);
        });

    return retryRequest(start, url, retryCount, retryTimeout).then(parse);
  };

  const defineVerb = method => (path, data, config) =>
    request(
      path,
      merge({}, config, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    );

  request.post = defineVerb('POST');
  request.put = defineVerb('PUT');
  request.patch = defineVerb('PATCH');

  request.delete = (path, config) =>
    request(
      path,
      extend({}, config, {
        method: 'DELETE',
      }),
    );

  request.uploadFile = (file, credentials) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('media_type', 'picture');
    formData.append('upload_token', credentials.uploadToken);
    formData.append('parent_folder_id', credentials.folderId);

    const config = {
      method: 'POST',
      body: formData,
    };

    return fetch(credentials.uploadUrl, config)
      .then(handleSuccess)
      .then(parseBody);
  };

  return request;
}

const appendPetriOvr = (path, petriOvr) => {
  if (!petriOvr) {
    return path;
  }
  const uri = new urijs(path);
  return uri.setQuery('petri_ovr', petriOvr);
};

function handleSuccess(response) {
  return response.status < 400 ? response : Promise.reject(response);
}

function parseBody(response) {
  return response.json().catch(() => Promise.resolve()); // catch is incase it's not json
}

function parseHeadersAndBody(response) {
  return response
    .json()
    .then(data => ({
      body: data,
      headers: response.headers,
    }))
    .catch(() => Promise.resolve()); // catch is incase it's not json
}

const trackResponse = (path, logResponse, trackError) => response => {
  const headers = [];
  try {
    if (response && response.headers && response.headers.entries) {
      for (const h of response.headers.entries()) {
        headers.push(h);
      }
    } else if (response && response.headers) {
      headers.push(JSON.stringify(response.headers));
    }
  } catch (error) {
    trackError(error);
  }

  logResponse([path, response && response.body ? response.body : response, headers]);
  return response;
};

function abortableFetch(request, config) {
  const controller = new AbortController();
  const signal = controller.signal;

  return {
    abort: () => controller.abort(),
    ready: fetch(request, { ...config, signal }),
  };
}
