import { createAction, ENTITY_TYPE_POSTS, urijs } from '@wix/communities-blog-client-common';
import { buildPaginationRequestParams, getTotalResults } from '../services/pagination';
import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePosts } from '../services/post-utils';
import { getPageSize } from '../selectors/pagination-selectors';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const FETCH_FEED_POSTS_REQUEST = 'feedPosts/FETCH_REQUEST';
export const FETCH_FEED_POSTS_SUCCESS = 'feedPosts/FETCH_SUCCESS';
export const FETCH_FEED_POSTS_FAILURE = 'feedPosts/FETCH_FAILURE';

export const fetchFeedPostsRequest = createAction(FETCH_FEED_POSTS_REQUEST);
export const fetchFeedPostsSuccess = createAction(
  FETCH_FEED_POSTS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchFeedPostsFailure = createAction(FETCH_FEED_POSTS_FAILURE);

export default function fetchFeedPosts({
  page = 1,
  pageSize: defaultPageSize,
  entityType = ENTITY_TYPE_POSTS,
  featuredOnly,
  excludeContent,
  section,
  fieldsets,
} = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchFeedPostsRequest({ entityType, page }));

    const pageSize = getPageSize(getState(), { overrideSettingsPageSize: defaultPageSize, section });
    const params = buildPaginationRequestParams(page, pageSize);

    // const promise = request.metered('/_api/posts')(  // TODO: fix metered request
    //   uri('/_api/posts').query({
    //     ...params,
    //     pinnedFirst: true,
    //     featuredOnly,
    //     excludeContent,
    //   }),
    //   {
    //     parseHeaders: true,
    //   },
    // );

    const promise = request(
      urijs('/_api/posts').query({
        ...params,
        pinnedFirst: true,
        featuredOnly,
        excludeContent,
        fieldsets,
      }),
      {
        parseHeaders: true,
      },
    );

    return promise
      .then(({ body = [], headers = {} }) =>
        dispatch(
          fetchFeedPostsSuccess(normalizePosts({ posts: body, blogCategoryIds: getCategoryIds(getState()) }), {
            page,
            entityType,
            entityCount: getTotalResults(headers),
            pageSize,
          }),
        ),
      )
      .catch(() => dispatch(fetchFeedPostsFailure({ entityType, page })))
      .then(() => promise);
  };
}

export const fetchFeedPostsPromisified = createPromisifiedAction(
  fetchFeedPosts,
  () => null,
  response => response.status,
);
