import { getVideoCount, getImageCount } from '../content-selectors';
import { CREATE_COMMENT_SUCCESS } from '../../store/comments/create-comment';
import { getPost } from '../../selectors/post-selectors';

const handler = (action, state) => {
  const comment = action.payload;
  const post = getPost(state, comment.postId);
  return {
    evid: 204,
    is_edited: 0,
    video_count: getVideoCount(comment.content),
    image_count: getImageCount(comment.content),
    comment_id: comment._id,
    post_id: comment.postId,
    post_stable_id: post.id,
    eventMeta: {
      description: 'comment published',
    },
  };
};

export const uouCreateCommentSuccessEvent = { [CREATE_COMMENT_SUCCESS]: handler };
