import { CREATE_USER_SUCCESS } from '../../../actions/create-user';
import { SET_USER } from '../../../actions/set-user';
import { LOGOUT_MEMBER_SUCCESS } from '../../../actions/logout-member';
import { FETCH_CURRENT_USER_SUCCESS } from '../../../actions/fetch-current-user';

export default (state = null, { type, payload = {} } = {}) => {
  switch (type) {
    case SET_USER:
    case CREATE_USER_SUCCESS:
    case FETCH_CURRENT_USER_SUCCESS:
      return { ...(state || {}), ...payload };
    case LOGOUT_MEMBER_SUCCESS:
      return null;
    default:
      return state;
  }
};
