import { omit, get } from 'lodash';

import { SET_POSTS } from '../../actions/set-posts';
import { FETCH_POST_SUCCESS } from '../../actions/fetch-post';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../../actions/fetch-category-posts';
import { FETCH_TAG_POSTS_SUCCESS } from '../../actions/fetch-tag-posts';
import { FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';
import { FETCH_RECENT_POSTS_SUCCESS } from '../../../post-page/actions/fetch-recent-posts';
import { FETCH_SEARCH_SUCCESS } from '../../../search/actions/fetch-search';
import { UPDATE_POST_SUCCESS } from '../../actions/update-post';
import { DELETE_POST_SUCCESS } from '../../actions/delete-post';
import { CREATE_POST_DRAFT_SUCCESS } from '../../actions/create-post-draft';
import { SAVE_POST_DRAFT_SUCCESS } from '../../actions/save-post-draft';
import { PUBLISH_POST_SUCCESS } from '../../actions/publish-post';
import { PIN_POST_SUCCESS } from '../../actions/pin-post';
import { UNPIN_POST_SUCCESS } from '../../actions/unpin-post';
import { ENABLE_POST_COMMENTS_SUCCESS } from '../../../comments/actions/enable-post-comments';
import { DISABLE_POST_COMMENTS_SUCCESS } from '../../../comments/actions/disable-post-comments';
import { SUBSCRIBE_REQUEST, SUBSCRIBE_FAILURE } from '../../actions/subscribe';
import { UNSUBSCRIBE_REQUEST, UNSUBSCRIBE_FAILURE } from '../../actions/unsubscribe';
import { POST_PUBLISHED } from '../../actions/blog-socket';
import { SET_USER } from '../../actions/set-user';
import { CLEAR_POSTS } from '../../actions/clear-posts';

const unionPosts = (oldPosts, newPosts) =>
  newPosts.reduce(
    (result, post) => {
      result[post._id] = post;
      return result;
    },
    { ...oldPosts },
  );

// eslint-disable-next-line complexity
const post = (state, action) => {
  switch (action.type) {
    case SET_USER:
      if (state.owner._id === action.payload._id) {
        return { ...state, owner: { ...state.owner, ...action.payload } };
      }
      return state;
    case SUBSCRIBE_REQUEST:
      if (state._id === action.payload._id) {
        return { ...state, isSubscribed: true };
      }
      return state;
    case SUBSCRIBE_FAILURE:
      if (state._id === action.payload._id) {
        return { ...state, isSubscribed: false };
      }
      return state;
    case UNSUBSCRIBE_REQUEST:
      if (state._id === action.payload._id) {
        return { ...state, isSubscribed: false };
      }
      return state;
    case UNSUBSCRIBE_FAILURE:
      if (state._id === action.payload._id) {
        return { ...state, isSubscribed: true };
      }
      return state;
    default:
      return state;
  }
};

// eslint-disable-next-line complexity
const posts = (state = {}, { type, payload } = {}) => {
  switch (type) {
    case CLEAR_POSTS:
      return {};
    case SET_POSTS:
      return unionPosts({}, payload);
    case FETCH_POST_SUCCESS:
      return unionPosts(state, [payload.post]);
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_RECENT_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_SUCCESS:
      return unionPosts(state, payload);
    case UPDATE_POST_SUCCESS:
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS:
      return unionPosts(state, [payload]);
    case POST_PUBLISHED:
    case CREATE_POST_DRAFT_SUCCESS:
    case PUBLISH_POST_SUCCESS:
      return unionPosts(state, [payload]);

    case SAVE_POST_DRAFT_SUCCESS:
      return unionPosts(state, [get(payload, 'post')]);

    case FETCH_SEARCH_SUCCESS:
      return unionPosts(
        state,
        payload.posts.filter(post => post.type === 'post'),
      );
    case DELETE_POST_SUCCESS:
      return omit(state, payload);
    case SUBSCRIBE_REQUEST:
    case SUBSCRIBE_FAILURE:
    case UNSUBSCRIBE_REQUEST:
    case UNSUBSCRIBE_FAILURE:
      if (payload.type === 'post') {
        return Object.keys(state).reduce((result, current) => {
          result[current] = post(state[current], { type, payload });
          return result;
        }, {});
      }
      return state;
    case SET_USER:
      return Object.keys(state).reduce((result, current) => {
        result[current] = post(state[current], { type, payload });
        return result;
      }, {});
    default:
      return state;
  }
};

export default posts;
